import React from 'react';

import Modal, { type ModalProps } from '../Modal';

const DeleteConfirmationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onAccept,
  onDecline,
  title,
  body,
  declineText = 'No',
  acceptText = 'Yes Delete',
  isLoading,
}) => {
  return (
    <Modal
      id="default-modal"
      title={title}
      body={
        <div className="mb-4 mt-8 text-center text-lg">
          <p>{body}</p>
        </div>
      }
      width="690px"
      isOpen={isOpen}
      onClose={onClose}
      acceptText={acceptText}
      declineText={declineText}
      onAccept={onAccept}
      onDecline={onDecline}
      headerBgColor="bg-red-600"
      acceptButtonBgColor="bg-red-600"
      crossButtonBgColor="bg-coral-red"
      headerTextColor="text-white"
      isLoading={isLoading}
    />
  );
};

export default DeleteConfirmationModal;

import Input from 'app/components/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';

// import AddOns from './AddOns';
// import AttributesAndVariants from './AttributesAndVariants';
// import Scheduling from './Schedualing';
// import Specifications from './Specifications';
interface PricingAndStockProps {
  register: any;
  errors: any;
  setValue: any;
  watch: any;
  index: number;
  updatedData?: any;
}

const PricingAndStock: React.FC<PricingAndStockProps> = ({
  // register,
  errors,
  // setValue,
  watch,
  index,
  // updatedData,
}: PricingAndStockProps) => {
  const { t } = useTranslation();

  const productDetails = watch('product_details') || [];
  const isIndexValid = index >= 0 && index < productDetails.length;

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="mr-5 md:w-1/5">
          <div className="flex h-14 items-center rounded-r-md bg-light-gray">
            <label className="items-center p-4 font-airbnb text-[16px] font-bold">
              {t('ProductListing.pricing')}
            </label>
          </div>
        </div>
        <div className="mr-5 w-full md:w-4/5">
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="mb-4 md:mb-0">
              <label
                htmlFor={t('ProductListing.base_price')}
                className="text-xs font-medium md:text-sm"
              >
                {t('ProductListing.base_price')}
              </label>
              <Input
                placeholder="20 €"
                containerClassName="relative flex items-center mb-2"
                inputClassName="focus:outline-none w-[300px] border border-gray-200 shadow-sm"
                height="33px"
                valueAsNumber
                alt="base_price"
                name={`product_details[${index}].pricing.base_price`}
                aria-label="price"
                type="number"
                // {...register(`product_details[${index}].pricing.base_price`)}
              />
              {errors.product_details?.[index]?.pricing?.base_price && (
                // <p className="text-xs text-red-600 md:text-sm">
                //   {errors?.product_details?.[index].pricing?.base_price.message}
                // </p>
                <p className="text-red-600">{t('ProductListing.base_error')}</p>
              )}
            </div>
            <div className="mb-4 ">
              <label
                htmlFor={t('ProductListing.stock')}
                className="p-2 text-xs font-medium md:text-sm"
              >
                {t('ProductListing.pricing_stock')}
                <span className="text-red-600">*</span>
              </label>
              {isIndexValid && (
                <Input
                  placeholder="20 €"
                  containerClassName="relative flex items-center mb-2"
                  inputClassName="focus:outline-none w-full border border-gray-200 shadow-sm"
                  height="33px"
                  valueAsNumber
                  alt="stock"
                  name={`product_details[${index}].pricing.stock`}
                  aria-label="stock"
                  type="number"
                  // {...register(`product_details[${index}].pricing.stock`)}
                />
              )}
              {errors.product_details?.[index]?.pricing?.stock && (
                <p className="text-xs text-red-600 md:text-sm">
                  {errors?.product_details?.[index].pricing?.stock.message}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {isIndexValid && (
        <>
          <Scheduling setValue={setValue} index={index} watch={watch} />

          <AttributesAndVariants
            setValue={setValue}
            register={register}
            watch={watch}
            index={index}
            errors={errors}
          />
          <AddOns
            setValue={setValue}
            watch={watch}
            index={index}
            errors={errors}
            register={register}
            updatedData={updatedData}
          />
          <Specifications
            register={register}
            watch={watch}
            setValue={setValue}
            index={index}
          />
        </>
      )} */}
    </>
  );
};

export default PricingAndStock;

import React from 'react';

interface IconButtonProps {
  src: string;
  alt: string;
  ariaLabel: string;
  onClick?: () => void;
}
export function IconButton({ src, alt, ariaLabel, onClick }: IconButtonProps) {
  return (
    <button
      type="button"
      className="relative rounded-full p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
      aria-label={ariaLabel}
      onClick={onClick}
    >
      <span className="absolute -inset-1.5" />
      <img src={src} alt={alt} />
    </button>
  );
}

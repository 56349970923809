import { getRefreshToken } from 'services/restApis';

const extendSession = async () => {
  try {
    const data: any = await getRefreshToken();
    localStorage.setItem('userToken', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    return;
  } catch (error) {
    localStorage.removeItem('access_token');
    localStorage.clear();
    window.location.href = `/login?from=${window.location.pathname}`;
  }
};

const SessionExpireRedirect = async (status: number) => {
  const refreshtoken = localStorage.getItem('refresh_token');
  if (refreshtoken) {
    extendSession();
  } else {
    if (status === 401 && window.location.pathname !== '/login') {
      localStorage.removeItem('access_token');
      localStorage.clear();
      window.location.href = `/login?from=${window.location.pathname}`;
    }
  }
};

export default SessionExpireRedirect;

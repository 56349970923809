import './style.scss';

import type { ButtonProps } from 'antd';
import { Button as AntdButton } from 'antd';
import type { FC } from 'react';
import React from 'react';

const Button: FC<
  ButtonProps & {
    htmlType?: 'button' | 'submit' | 'reset';
  }
> = ({ children, htmlType = 'button', className = '', ...props }) => {
  let typeClass = '';
  if (htmlType === 'submit') {
    typeClass = 'submit-class';
  } else if (htmlType === 'reset') {
    typeClass = 'reset-class';
  }

  return (
    <AntdButton
      {...props}
      className={`${className} ${typeClass}`.trim()}
      htmlType={htmlType}
    >
      {children}
    </AntdButton>
  );
};

export default Button;

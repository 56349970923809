import { Button } from 'antd';
import DeleteConfirmationModal from 'app/components/DeleteConfirmationModal';
import Table from 'app/components/Table';
import checkOutlineCircled from 'assets/icons/checkOutlineCircled.svg';
import crossOutlinedCircled from 'assets/icons/crossOutlinedCircled.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  useAcceptPayment,
  useGetPaymentHistory,
  useRejectPayment,
} from 'hooks/queries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showToastNotification } from 'utils/toastNotification';

dayjs.extend(utc);

export const History = () => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>('');
  const { data, isLoading, refetch } = useGetPaymentHistory({
    pageSize,
    currentPage,
  });

  const { mutateAsync: rejectPayment, isPending } = useRejectPayment();
  const { mutateAsync: acceptPayment } = useAcceptPayment();

  useEffect(() => {
    refetch();
  }, [currentPage, pageSize]);

  const columns = [
    {
      title: t('columns_header.influencer_name'),
      dataIndex: 'influencer_name',
      key: 'influencer_name',
    },
    {
      title: t('columns_header.date'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => {
        return dayjs.utc(date).format('DD-MM-YYYY');
      },
    },
    {
      title: t('columns_header.amount_earned'),
      dataIndex: 'amount_earned',
      key: 'amount_earned',
      render: (text: any) => `€ ${text ?? 0}`,
    },
    {
      title: t('columns_header.payment_method'),
      dataIndex: 'payment_method',
      key: 'payment_method',
    },
    {
      title: t('columns_header.payment_Status'),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (_: any, record: any) => {
        return record?.payment_status === 'PENDING' ? (
          <div className="flex items-center gap-3">
            <Button
              className="border-none !p-0"
              onClick={() => AcceptPayment(record?.uuid)}
            >
              <img src={checkOutlineCircled} width={30} />
            </Button>
            <Button
              className="border-none !p-0"
              onClick={() => {
                setOpen(true);
                setCurrentId(record?.uuid);
              }}
            >
              <img src={crossOutlinedCircled} width={30} />
            </Button>
          </div>
        ) : (
          <p
            className={`${record?.payment_status === 'PAID' ? 'text-green-700' : 'text-red-700'} text-sm font-bold `}
          >
            {record?.payment_status}
          </p>
        );
      },
    },
  ];

  const raiseError = (status: number) => {
    showToastNotification(
      '',
      'error',
      `${t('influencer.error')}: ${status || 'Unknown'} ${t('influencer.try_again')}`,
    );
  };

  const AcceptPayment = async (id: string) => {
    try {
      await acceptPayment(id);
      showToastNotification('payment.appproved.success', 'success');
      refetch();
    } catch (error: any) {
      raiseError(error?.response?.status);
    } finally {
      setOpen(false);
    }
  };

  const RejectPayment = async () => {
    try {
      await rejectPayment(currentId);
      showToastNotification('payment.reject.success', 'success');
      refetch();
    } catch (error: any) {
      raiseError(error?.response?.status);
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        data={data?.data?.length > 0 ? data?.data : []}
        pagination
        pageSize={pageSize}
        total={data?.total || 0}
        currentPage={currentPage}
        isLoading={isLoading}
        skeletonRowCount={pageSize}
        onPageChange={(page, size) => {
          setCurrentPage(page);
          if (size) setPageSize(size);
        }}
      />

      <DeleteConfirmationModal
        isOpen={open}
        onDecline={() => setOpen(false)}
        onAccept={RejectPayment}
        onClose={() => setOpen(false)}
        title={t('influencer.reject_payment')}
        declineText={t('delete_modal.noButton')}
        acceptText={t('delete_modal.yesButton')}
        isLoading={isPending}
      />
    </>
  );
};

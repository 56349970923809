import clsx from 'clsx';
import { useGetCategories, useGetProductTypes } from 'hooks/queries';
import React from 'react';
import type { UseFormClearErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dropdownIcon from '../../../assets/icons/arrowIcon.svg';

interface Category {
  title: string;
  uuid: string;
}

interface CategoryAndProductTypeProps {
  setValue: any;
  errors: any;
  clearErrors: UseFormClearErrors<any>;
  register: any;
  selected: string[];
  value: string;
  onChange?: (selected: string[]) => void;
  data?: any;
}

const CategoryAndProductType: React.FC<CategoryAndProductTypeProps> = ({
  setValue,
  errors,
  value,
  register,
  clearErrors,
}) => {
  const { t } = useTranslation();

  const {
    data: CategoryData,
    isLoading: isCategoriesLoading,
    error: categoriesError,
    // isSuccess: isCategoriesSuccess,
  } = useGetCategories({});

  const { isLoading: isProductTypeLoading, error: productTypeError } =
    useGetProductTypes({});

  if (isCategoriesLoading || isProductTypeLoading) {
    return <p className="p-10 text-red-600">{t('ProductListing.loading')}</p>;
  }

  if (categoriesError || productTypeError) {
    return (
      <p className="text-red-600">{t('ProductListing.error_loading_data')}</p>
    );
  }

  const options: { label: string; value: string }[] = Array.isArray(
    CategoryData?.data,
  )
    ? (CategoryData.data as Category[]).map(category => ({
        label: category.title,
        value: category.uuid,
      }))
    : [];

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedOptionValue = event.target.value;
    setValue('category_uuid', selectedOptionValue);
    clearErrors('category_uuid');
  };

  return (
    <div className="pt-16">
      <label
        htmlFor="category_uuid"
        className="mb-2 block font-airbnb text-[16px]  font-medium text-black"
      >
        {t('ProductListing.choose_category')}
        <span className="text-red-600">*</span>
      </label>
      <div className="relative">
        <select
          id="category_uuid"
          onChange={handleCategoryChange}
          defaultValue={value || ''}
          {...register('category_uuid')}
          className={clsx(
            'block w-full appearance-none rounded-lg border border-gray-300 bg-white p-2.5 text-sm shadow-md focus:ring-gray-100',
            'focus:outline-none',
          )}
        >
          <option value="" disabled>
            {t('ProductListing.choose_category')}
          </option>
          {options.length > 0 ? (
            options.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          ) : (
            <option value="" disabled>
              {t('ProductListing.info_message')}
            </option>
          )}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-6">
          <img
            src={dropdownIcon}
            alt={t('ProductListing.dropdown_icon')}
            width={10}
            height={10}
          />
        </div>
      </div>
      {errors?.category_uuid && (
        <p className="text-red-600">{t('ProductListing.category_error')}</p>
      )}
    </div>
  );
};

export default CategoryAndProductType;

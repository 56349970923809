import { Tooltip } from 'antd';
import CategoryIcon from 'assets/icons/categories.svg';
import CategoryDarkIcon from 'assets/icons/categoriesDark.svg';
import DashboardIcon from 'assets/icons/dashboard.svg';
import DashboardDarkIcon from 'assets/icons/dashboardDark.svg';
import DocumentTypeDarkIcon from 'assets/icons/documentDarkIcon.svg';
import DocumentTypeIcon from 'assets/icons/documentIcon.svg';
// import InfluencerIcon from 'assets/icons/influencer.svg';
// import InfluencerDarkIcon from 'assets/icons/influencer_dark.svg';
import Logo from 'assets/icons/logo.svg';
// import OrderIcon from 'assets/icons/myOrders.svg';
// import OrderDarkIcon from 'assets/icons/myOrdersdark.svg';
import ReceiptDarkIcon from 'assets/icons/productDark.svg';
import ProductListingIcon from 'assets/icons/productListing.svg';
import ProductListingDarkIcon from 'assets/icons/productListingDark.svg';
import ReceiptIcon from 'assets/icons/receipt.svg';
import LogoSingle from 'assets/icons/rentArroundLogoSingle.svg';
import SellerDark from 'assets/icons/sellers_darkicon.svg';
import Seller from 'assets/icons/sellers_icon.svg';
import SettingIcon from 'assets/icons/setting.svg';
import SettingDarkIcon from 'assets/icons/settingDark.svg';
import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { auth } from 'utils/firebaseAuthConfig';

interface SidebarItemProps {
  to: string;
  icon: string;
  selectedIcon: string;
  label: string;
  isSelected: boolean;
  onClick?: any;
}

const SideBarItemContainer = ({
  children,
  to,
  isSelected,
}: {
  children: React.ReactNode;
  to: string;
  isSelected: boolean;
}) => {
  return to === '/login' ? (
    <div
      onClick={async () => {
        await signOut(auth);
        localStorage.removeItem('userToken');
        localStorage.removeItem('userInfo');
        window.location.href = `/login?from=${window.location.pathname}`;
      }}
      className="group flex cursor-pointer items-center  rounded-lg p-2 text-white  hover:bg-[rgba(226,226,226,0.20)] hover:opacity-100 lg:justify-normal dark:text-white dark:hover:bg-[rgba(226,226,226,0.20)]"
    >
      {children}
    </div>
  ) : (
    <Link
      to={to}
      className={`group flex items-center rounded-lg p-2 ${
        isSelected
          ? 'bg-white text-black'
          : 'text-white hover:bg-[rgba(226,226,226,0.20)] hover:opacity-100 dark:text-white dark:hover:bg-[rgba(226,226,226,0.20)]'
      }`}
    >
      {children}
    </Link>
  );
};

const SidebarItem = ({
  to,
  icon,
  selectedIcon,
  label,
  isSelected,
}: SidebarItemProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    function handleResize() {
      window.innerWidth <= 1024 && setVisible(false);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseEnter = (value: boolean) => {
    if (value) {
      window.innerWidth <= 1024 && setVisible(value);
    } else {
      setVisible(value);
    }
  };

  return (
    <Tooltip open={visible} placement="rightTop" title={label}>
      <li
        onMouseEnter={() => handleMouseEnter(true)}
        onMouseLeave={() => handleMouseEnter(false)}
      >
        <SideBarItemContainer to={to} isSelected={isSelected}>
          <div className="group flex items-center justify-center rounded-lg   lg:justify-normal ">
            <img src={isSelected ? selectedIcon : icon} alt={label} />
            <span
              className={`ms-3 hidden lg:block ${isSelected ? 'text-black' : ''}`}
            >
              {label}
            </span>
          </div>
        </SideBarItemContainer>
      </li>
    </Tooltip>
  );
};

export function SideBar() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;

  const links = [
    {
      to: '/',
      icon: DashboardIcon,
      selectedIcon: DashboardDarkIcon,
      label: t('sideBar.dashboard'),
    },
    {
      to: '/users',
      icon: Seller,
      selectedIcon: SellerDark,
      label: t('sideBar.users'),
    },
    // {
    //   to: '/influencer',
    //   icon: InfluencerIcon,
    //   selectedIcon: InfluencerDarkIcon,
    //   label: t('sideBar.influencer'),
    // },
    // {
    //   to: '/orders',
    //   icon: OrderIcon,
    //   selectedIcon: OrderDarkIcon,
    //   label: t('sideBar.orders'),
    // },
    {
      to: '/product-type',
      icon: CategoryIcon,
      selectedIcon: CategoryDarkIcon,
      label: t('sideBar.productType'),
    },
    {
      to: '/add-product',
      icon: ReceiptIcon,
      selectedIcon: ReceiptDarkIcon,
      label: t('sideBar.addProduct'),
    },
    {
      to: '/product-listings',
      icon: ProductListingIcon,
      selectedIcon: ProductListingDarkIcon,
      label: t('sideBar.productListing'),
    },
    {
      to: '/categories',
      icon: CategoryIcon,
      selectedIcon: CategoryDarkIcon,
      label: t('sideBar.categories'),
    },
    {
      to: '/document-type',
      icon: DocumentTypeIcon,
      selectedIcon: DocumentTypeDarkIcon,
      label: t('sideBar.documentType'),
    },
  ];

  const accountLinks = [
    {
      to: '/settings',
      icon: SettingIcon,
      selectedIcon: SettingDarkIcon,
      label: t('sideBar.Settings_title'),
    },
    {
      to: '/login',
      icon: ReceiptIcon,
      selectedIcon: ReceiptDarkIcon,
      label: t('sideBar.logout'),
    },
  ];

  return (
    <div className="fixed h-[calc(100vh-2.5rem)] w-[78px] lg:w-[254px]">
      <aside
        id="default-sidebar"
        className="h-full rounded-2xl bg-dark-blue px-3 py-4 sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="flex h-16 w-32 items-center ps-2.5 lg:ml-8">
          <Link to="/dashboard">
            <img
              src={LogoSingle}
              alt="logo"
              className="block size-10 lg:hidden"
            />
            <img src={Logo} alt="logo" className="hidden lg:block" />
          </Link>{' '}
        </div>
        <hr className="mb-8" />
        <ul className="max-h-[calc(100vh-170px)] space-y-2 overflow-auto font-medium transition-transform">
          {links.map(link => (
            <SidebarItem
              key={link.to}
              to={link.to}
              icon={link.icon}
              selectedIcon={link.selectedIcon}
              label={link.label}
              isSelected={currentPath === link.to}
            />
          ))}
          <div className="hidden font-bold  text-white lg:block">
            {t('sideBar.account_pages')}
          </div>
          {accountLinks.map(link => (
            <SidebarItem
              key={link.to}
              to={link.to}
              icon={link.icon}
              selectedIcon={link.selectedIcon}
              label={link.label}
              isSelected={currentPath === link.to}
            />
          ))}
        </ul>
      </aside>
    </div>
  );
}

import React from 'react';

import { NavBar } from '../Header';
import { SideBar } from '../SideBar';

export const Layout = ({ children }: any) => {
  return (
    <div className="flex min-h-screen flex-col gap-10 bg-slate-100 p-5 md:flex-row ">
      <SideBar />
      <div className="ml-24 flex h-[calc(100vh-2.5rem)] w-[calc(100vw-7.5rem)] grow flex-col  lg:ml-72 lg:w-[calc(100vw-19.5rem)]">
        <div className="sticky top-5 ">
          <NavBar />
        </div>
        <div className="my-3 flex-1 overflow-auto">{children}</div>
      </div>
    </div>
  );
};

import 'react-toastify/dist/ReactToastify.css';

import i18n from 'i18next';
import React from 'react';
import { toast } from 'react-toastify';

type ToastType = 'success' | 'error' | 'warning' | 'info';

export const showToastNotification = (
  key: string,
  type: ToastType = 'success',
  title?: string,
) => {
  const message = i18n.t(`restApiNotifications.${key}`);
  const content = (
    <div>
      {title && <strong>{title}</strong>}
      {title && <br />}
      {key && <span>{message}</span>}
    </div>
  );

  switch (type) {
    case 'success':
      toast.success(content);
      break;
    case 'error':
      toast.error(content);
      break;
    case 'warning':
      toast.warning(content);
      break;
    case 'info':
      toast.info(content);
      break;
    default:
      toast.success(content);
      break;
  }
};

import '../../components/Table/index.css';

import { Pagination, Table as AntTable } from 'antd';
import React, { useEffect, useState } from 'react';

import SkeletonLoader from '../SkeltonLoader';

interface TableColumn {
  title: string | React.ReactNode;
  dataIndex?: string;
  key: string;
  render?: (text: any, record: any) => React.ReactNode;
}

interface TableProps {
  columns?: TableColumn[];
  data: any[];
  title?: string;
  tableClassName?: string;
  tbodyClassName?: string;
  thClassName?: string;
  tdClassName?: string;
  titleClassName?: string;
  columnStyles?: { [key: string]: string };
  isLoading?: boolean;
  checkboxColumns?: string[];
  skeletonRowCount?: number;
  pagination?: boolean;
  pageSize?: number;
  total?: number;
  onPageChange?: (page: number, pageSize?: number) => void;
  currentPage?: number;
}

const Table: React.FC<TableProps> = ({
  columns = [],
  data,
  title,
  tableClassName,
  tbodyClassName,
  thClassName,
  tdClassName,
  titleClassName,
  columnStyles = {},
  isLoading = false,
  checkboxColumns = [],
  skeletonRowCount = 5,
  pagination = true,
  pageSize = 10,
  total = 0,
  onPageChange,
  currentPage = 1,
}) => {
  const [internalPageSize, setInternalPageSize] = useState(pageSize);

  useEffect(() => {
    setInternalPageSize(pageSize);
  }, [pageSize]);

  const handlePaginationChange = (page: number, newPageSize?: number) => {
    if (onPageChange) {
      onPageChange(page, newPageSize);
    }
  };

  const adjustedColumns = columns.map((col, index) => {
    const isCheckboxColumn = checkboxColumns.includes(col.dataIndex || '');
    const isFirstColumn = index === 0;

    const getAlignment = () => {
      if (isCheckboxColumn) return 'text-center';
      if (col.key === 'actions') return 'text-center';
      return 'text-left';
    };

    const getDivAlignment = () => {
      if (isCheckboxColumn) return 'flex items-center justify-center';
      if (col.key === 'actions') return 'flex items-center justify-center';
      return 'flex items-center justify-start';
    };

    const textAlign = getAlignment();
    const className = `${getAlignment()} ${thClassName}`;
    const divAlign = getDivAlignment();

    return {
      ...col,
      render: (text: any, record: any) => (
        <div
          className={`${tdClassName} ${columnStyles[col.key] || ''} ${divAlign}`}
          style={{
            fontWeight: isFirstColumn ? 'bold' : undefined,
          }}
        >
          {col.render ? col.render(text, record) : text}
        </div>
      ),
      className,
      title: <div className={`${textAlign}`}>{col.title}</div>,
    };
  });

  return (
    <>
      <div className="overflow-x-auto rounded-lg border">
        {title && (
          <div className="flex items-center justify-between p-4">
            <h3
              className={`text-xl font-semibold text-dark-gray ${titleClassName}`}
            >
              {title}
            </h3>
          </div>
        )}
        <div
          className={`w-[74vw] min-w-full max-w-full divide-y divide-gray-200 whitespace-nowrap ${tableClassName}`}
        >
          <AntTable
            columns={
              isLoading
                ? columns.map(column => ({
                    ...column,
                    render: () => <SkeletonLoader />,
                  }))
                : adjustedColumns
            }
            dataSource={
              isLoading
                ? Array.from({ length: skeletonRowCount }).map(() => {})
                : data
            }
            pagination={false}
            rowKey="key"
            className={tbodyClassName}
          />
        </div>
      </div>
      {pagination && total >= 10 && (
        <div className="custom-hidden-select mt-4 flex justify-end">
          <Pagination
            current={currentPage}
            pageSize={internalPageSize}
            total={total}
            onChange={handlePaginationChange}
            showSizeChanger
            onShowSizeChange={(_, size) => {
              setInternalPageSize(size);
              if (onPageChange) onPageChange(1, size);
            }}
            disabled={isLoading}
          />
        </div>
      )}
    </>
  );
};

export default Table;

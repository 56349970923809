'use client';

import 'react-image-gallery/styles/css/image-gallery.css';

import PlaceholderImage from 'assets/img/placeholderImage.png';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import ReactQuill from 'react-quill';
import type { ProductTypeFormData } from 'types/entities';

interface ImageItem {
  original: string;
  thumbnail: string;
  id: number;
}
interface ProductDetailCardProps {
  item: ProductTypeFormData;
}
const ProductDetailCard: React.FC<ProductDetailCardProps> = ({ item }) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const getImageSrc = (src?: string) => src || PlaceholderImage;
  const images: ImageItem[] = item?.images?.length
    ? item.images
        .filter((image, index, self) => self.indexOf(image) === index)
        .map((image, index) => ({
          original: getImageSrc(image),
          thumbnail: getImageSrc(image),
          id: index,
        }))
    : [
        {
          original: PlaceholderImage,
          thumbnail: PlaceholderImage,
          id: 0,
        },
      ];

  const showThumbnailSlider = images.length > 1;
  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };
  return (
    <div className="relative mx-auto w-full max-w-[1200px] p-6 pl-0">
      <h1 className="text-[26px] font-bold text-black">{item?.title}</h1>
      <div className="mt-6 flex flex-col md:flex-row">
        {showThumbnailSlider && (
          <div className="scrollbar-hidden mb-4 max-h-[560px] overflow-y-auto md:mb-0 md:mr-4 md:w-[80px]">
            <div className="flex flex-col">
              {images.map(image => (
                <img
                  key={image.id}
                  src={image.thumbnail}
                  className={`mb-2 h-[90px] w-[80px] cursor-pointer rounded object-cover md:h-[80px] ${
                    image.id === images[selectedIndex]?.id
                      ? 'border-2 border-blue-500'
                      : ''
                  }`}
                  alt={`Thumbnail ${image.id}`}
                  onClick={() => setSelectedIndex(images.indexOf(image))}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setSelectedIndex(images.indexOf(image));
                    }
                  }}
                />
              ))}
            </div>
          </div>
        )}

        <div className=" w-full  shadow-sm">
          <ImageGallery
            items={images}
            showThumbnails={false}
            showFullscreenButton
            slideDuration={2}
            showNav={false}
            showPlayButton={false}
            startIndex={selectedIndex}
            autoPlay
            renderItem={galleryItem => (
              <img
                src={galleryItem.original}
                className={` w-full rounded object-cover ${
                  isFullscreen
                    ? ' h-screen object-cover'
                    : 'h-[300px] object-cover md:h-[548px]'
                }`}
                alt="Selected Image"
              />
            )}
            onScreenChange={() => {
              handleFullscreenToggle();
            }}
          />
        </div>
      </div>
      {!isFullscreen && (
        <div className="mx-auto mt-8 w-full">
          <div className="rounded bg-zinc-100 p-4 text-lg font-bold text-stone-800">
            {t('product_detail.description')}{' '}
          </div>
        </div>
      )}
      {!isFullscreen && item?.description && (
        <ReactQuill
          value={item?.description}
          readOnly
          modules={{ toolbar: false }}
          className="ql-editor  mt-6 h-auto whitespace-pre-wrap rounded border p-4 text-justify text-[16px]"
        />
      )}
    </div>
  );
};
export default ProductDetailCard;

import clsx from 'clsx';
import React, { useState } from 'react';

export interface Tab {
  id: string;
  label: string;
  content?: React.ReactNode;
  className?: string;
}

interface TabsProps {
  tabs: Tab[];
  activeTabIndex?: number;
  onTabChange?: (index: number) => void;
  className?: string;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTabIndex,
  onTabChange,
  className,
}) => {
  const [internalActiveTabIndex, setInternalActiveTabIndex] = useState(
    activeTabIndex ?? 0,
  );

  const handleTabChange = (index: number) => {
    if (onTabChange) {
      onTabChange(index);
    } else {
      setInternalActiveTabIndex(index);
    }
  };

  const currentActiveTabIndex = onTabChange
    ? activeTabIndex ?? 0
    : internalActiveTabIndex;

  return (
    <div className="flex w-full flex-col items-center justify-center">
      {tabs.length > 1 && (
        <ul
          className={`flex space-x-2 rounded-full bg-gray-100 text-center text-sm font-medium text-gray-500 sm:space-x-4 md:space-x-6 ${className}`}
          role="tablist"
        >
          {tabs.map((tab, index) => (
            <li role="presentation" key={tab.id}>
              <button
                type="button"
                className={clsx(
                  'inline-block rounded-full px-4 py-2 text-xs font-normal sm:px-6 sm:py-3 sm:text-sm md:px-8 md:py-3 md:text-base',
                  {
                    'text-white bg-indigo-800': index === currentActiveTabIndex,
                    'text-black-shade-100 dark:text-black bg-light-gray-500 hover:text-gray-900 hover:bg-gray-100':
                      index !== currentActiveTabIndex,
                  },
                )}
                onClick={() => handleTabChange(index)}
                role="tab"
                aria-selected={index === currentActiveTabIndex}
              >
                {tab.label}
              </button>
            </li>
          ))}
        </ul>
      )}
      <div className="mt-4 w-full">
        {tabs.map((tab, index) => (
          <div
            key={tab.id}
            className={clsx('rounded-lg', {
              hidden: index !== currentActiveTabIndex,
            })}
            role="tabpanel"
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;

import { Skeleton } from 'antd';
import React from 'react';

interface SkeletonLoaderProps {
  rows?: number;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ rows = 1 }) => {
  return (
    <>
      {Array.from({ length: rows }).map(_ => (
        <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
      ))}
    </>
  );
};

export default SkeletonLoader;

import React from 'react';

const ProductSkeletonLoader = () => {
  return (
    <div role="status" className="rounded-lg bg-white p-4 shadow-md">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <div className="w-full md:col-span-2 lg:col-span-2">
          <div className="p-6">
            <div className="mb-6 h-6 w-3/4 rounded-full bg-gray-300" />

            <div className="mb-4">
              <div className="mb-2 h-8 w-full rounded-full bg-gray-300" />
              <div className="h-8 w-full rounded-full bg-gray-300" />
            </div>

            <div className="mb-6">
              <div className="mb-2 h-8 w-1/2 rounded-full bg-gray-300" />
              <div className="h-[200px] rounded-xl bg-gray-300" />
            </div>

            <div className="my-5 flex flex-col space-y-4">
              <div className="h-8 w-3/4 rounded-full bg-gray-300" />
              <div className="h-8 w-2/3 rounded-full bg-gray-300" />
              <div className="h-8 w-5/6 rounded-full bg-gray-300" />
            </div>
          </div>
        </div>

        <div className="mt-5 w-full md:col-span-1 lg:col-span-1">
          <div className="h-[200px] rounded-xl bg-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default ProductSkeletonLoader;

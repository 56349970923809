import SearchInput from 'app/components/SearchInput';
import Table from 'app/components/Table';
import SearchIcon from 'assets/icons/searchIcon.svg';
import { useGetUsers } from 'hooks/queries';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Users = () => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState('');
  const { data, isLoading, isError, error, refetch } = useGetUsers(
    {
      pageSize,
      currentPage,
      filter: searchValue,
    },
    { enabled: false },
  );
  useEffect(() => {
    refetch();
  }, [currentPage, pageSize, searchValue]);
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    refetch();
  };
  const filteredData = data?.data?.filter((record: any) => {
    return record?.name.toLowerCase().includes(searchValue.toLowerCase());
  });
  const columns = [
    {
      title: t('columns_header.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('columns_header.email'),
      dataIndex: 'email',
      key: 'email',
    },
  ];

  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <div className="rounded-xl border border-gray-300 bg-white p-7 shadow-md">
      <div className="flex flex-row justify-between pb-5 max-[810px]:flex-col">
        <div className="flex flex-col">
          <div className="text-lg font-bold">{t('sideBar.users')}</div>
          <div className="flex flex-row">
            <Link to="/">{t('addProduct.dashboard')}</Link>
            <span className="px-2">/</span>
            <div className="text-blue-800">
              <Link to="/users">{t('sideBar.users')}</Link>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-y-4 max-[810px]:flex-col-reverse max-[810px]:pt-5 ">
          <div className="pr-2">
            <form className="mx-auto max-w-md max-[810px]:max-w-full">
              <SearchInput
                value={searchValue}
                onChange={handleSearchChange}
                iconPosition="left"
                placeholder={t('sideBar.searchForAnything')}
                icon={SearchIcon}
                inputClassName="py-3 text-sm cursor-pointer w-full"
              />
            </form>
          </div>
        </div>
      </div>{' '}
      <Table
        columns={columns}
        data={filteredData}
        pagination
        pageSize={pageSize}
        total={data?.total || 0}
        currentPage={currentPage}
        onPageChange={(page, size) => {
          setCurrentPage(page);
          if (size) setPageSize(size);
        }}
        isLoading={isLoading}
      />
    </div>
  );
};

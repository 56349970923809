import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Layout } from './components/Layout';
import ProtectedRoute from './components/Route';
import { Categories } from './pages/Categories/Loadable';
import { DocumentType } from './pages/DocumentType/Loadable';
import { HomePage } from './pages/HomePage/Loadable';
import { Influencer } from './pages/Influencer';
import { MyOrders } from './pages/MyOrders/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import AddProductListing from './pages/product-listing';
// import ProductDetail from './pages/ProductDetails/ProductDetail';
import ProductDetailTab from './pages/ProductDetails/[id]';
import { ProductListing } from './pages/ProductListing/Loadable';
import { ProductType } from './pages/ProductType/Loadable';
import { Settings } from './pages/Setting/Loadable';
import { Signin } from './pages/Signin/Loadable';
import UpdateListing from './pages/UpdateListing';
import { Users } from './pages/Users';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Signin />} />
      <Route
        path="/"
        element={
          <Layout>
            <HomePage />
          </Layout>
        }
      />
      <Route
        path="/users"
        element={
          <Layout>
            <Users />
          </Layout>
        }
      />
      <Route
        path="/influencer"
        element={
          <Layout>
            <Influencer />
          </Layout>
        }
      />
      <Route
        path="/orders"
        element={
          <Layout>
            <MyOrders />
          </Layout>
        }
      />
      <Route
        path="/categories"
        element={
          <Layout>
            <Categories />
          </Layout>
        }
      />
      <Route
        path="/add-product"
        element={
          <ProtectedRoute>
            <Layout>
              <AddProductListing />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/update-product/:uuid"
        element={
          <ProtectedRoute>
            <Layout>
              <UpdateListing />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-listings"
        element={
          <Layout>
            <ProductListing />
          </Layout>
        }
      />
      <Route
        path="/product-type"
        element={
          <Layout>
            <ProductType />
          </Layout>
        }
      />
      <Route
        path="/view-listing/:id"
        element={
          <Layout>
            <ProductDetailTab />
          </Layout>
        }
      />
      <Route
        path="/document-type"
        element={
          <Layout>
            <DocumentType />
          </Layout>
        }
      />
      <Route
        path="/settings"
        element={
          <Layout>
            <Settings />
          </Layout>
        }
      />
      <Route
        path="*"
        element={
          <Layout>
            <NotFoundPage />
          </Layout>
        }
      />
    </Routes>
  );
};

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - RentAround"
        defaultTitle="RentAround Admin Panel"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="RentAround Admin Panel" />
      </Helmet>
      <AppRoutes />
    </BrowserRouter>
  );
}

import { LoadingIndicator } from 'app/components/LoadingIndicator';
import * as React from 'react';
import { lazyLoad } from 'utils/loadable';

export const ProductType = lazyLoad(
  () => import('./index'),
  module => module.ProductType,
  {
    fallback: (
      <div className="flex h-screen w-full justify-center">
        <LoadingIndicator />
      </div>
    ),
  },
);

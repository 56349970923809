import dayjs from 'dayjs';

export const formatDateTime = (
  dateString: string,
  format: string = 'YYYY-MM-DD HH:mm',
) => {
  return dayjs(dateString).format(format);
};

export const getButtonStyling = (isActive: boolean) => {
  return `min-w-36 h-10 !bg-white border  ${isActive ? 'text-dark-blue border border-dark-blue hover:!border-dark-blue hover:!text-dark-blue' : 'hover:!border-[#d9d9d9] hover:!text-black'}`;
};

import { useAuth } from 'contexts/authContext';
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, accessToken } = useAuth() || {};

  if (user === null && accessToken === null) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedRoute;

import './style.scss';

import { Modal as AntdModal } from 'antd';
import CrossIcon from 'assets/icons/cross.svg';
import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

import Button from '../Button';

export interface ModalProps {
  id?: string;
  title?: string;
  body?: string | React.ReactNode;
  isOpen: boolean;
  acceptText?: string;
  declineText?: string;
  onClose: () => void;
  onAccept?: () => void;
  onDecline?: () => void;
  headerBgColor?: string;
  headerTextColor?: string;
  acceptButtonBgColor?: string;
  crossButtonBgColor?: string;
  width?: string;
  isLoading?: boolean;
}

const Modal: FC<ModalProps> = ({
  title,
  body,
  isOpen,
  onClose,
  acceptText = 'I accept',
  declineText = 'No',
  onAccept,
  onDecline,
  headerBgColor = '',
  headerTextColor = '',
  acceptButtonBgColor = 'bg-lime-green',
  crossButtonBgColor = 'bg-dark-blue',
  width = '400px',
  isLoading,
}) => {
  const headerClassName = clsx(headerBgColor, title ? 'p-5' : 'px-5 pt-8');

  return (
    <>
      <AntdModal
        open={isOpen}
        onCancel={onClose}
        onClose={onClose}
        width={width}
        title={
          <div
            className={`${headerClassName} relative flex items-center justify-between rounded-b-none rounded-t-[11px]`}
          >
            {title && (
              <h3
                className={`font-airbnb text-xl font-semibold ${headerTextColor}`}
              >
                {title}
              </h3>
            )}
          </div>
        }
        footer={
          onAccept || onDecline ? (
            <div className="flex items-center justify-center gap-4 rounded-b border-gray-200 p-4 dark:border-gray-600">
              {onDecline && (
                <Button
                  htmlType="reset"
                  className="ml-3 rounded-3xl border border-dark-blue bg-white px-5 py-2.5 text-sm font-medium text-dark-blue"
                  onClick={onDecline}
                >
                  {declineText}
                </Button>
              )}
              {onAccept && (
                <Button
                  loading={isLoading}
                  className={`danger-class rounded-3xl ${acceptButtonBgColor} px-5 py-3 text-center text-sm font-medium text-white focus:outline-none`}
                  onClick={onAccept}
                >
                  {acceptText}
                </Button>
              )}
            </div>
          ) : (
            true
          )
        }
        centered
        className="common-modal"
        closeIcon={
          <div className="">
            <button
              onClick={onClose}
              type="button"
              className={`flex items-center justify-center rounded-full ${crossButtonBgColor} p-2 text-base text-white`}
            >
              <img src={CrossIcon} className="w-5 max-w-none" alt="cross" />
            </button>
          </div>
        }
      >
        <div className="px-4">
          <div className="font-airbnb text-base leading-relaxed">{body}</div>
        </div>
      </AntdModal>
    </>
  );
};

export default Modal;

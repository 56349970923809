import { Input } from 'antd';
import CrossIcon from 'assets/icons/cross.svg';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type SearchInputProps = {
  value: string;
  icon: string;
  placeholder?: string;
  onChange: (value: string) => void;
  iconPosition?: 'left' | 'right';
  containerClassName?: string;
  inputClassName?: string;
};

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  placeholder = 'Search...',
  onChange,
  iconPosition = 'left',
  icon,
  containerClassName = '',
  inputClassName = '',
}) => {
  const { t } = useTranslation();

  const handleClear = useCallback(() => {
    onChange('');
  }, [onChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const inputPrefix = iconPosition === 'left' && (
    <img className="search-icon" src={icon} alt={t('ProductListing.search')} />
  );

  const inputSuffix = (
    <>
      {iconPosition === 'right' && (
        <img
          src={icon}
          alt={t('ProductListing.search')}
          style={{ color: 'black' }}
        />
      )}
      {value && (
        <button
          type="button"
          onClick={handleClear}
          className="cross-icon-class search-icon cursor-pointer"
          aria-label="Clear"
        >
          <img
            src={CrossIcon}
            alt="cross"
            className="size-[15px] rounded-full bg-gray-500"
          />
        </button>
      )}
    </>
  );

  return (
    <div className={`relative flex items-center ${containerClassName}`}>
      <Input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={`w-64 rounded-lg border border-gray-300 py-1 pr-3 focus:border-blue-500 focus:outline-none ${inputClassName}`}
        prefix={inputPrefix}
        suffix={inputSuffix}
      />
    </div>
  );
};

export default SearchInput;

import React from 'react';

export const SkeletonProductDetails = () => (
  <div className="p-4 sm:p-6 lg:p-8">
    <div className="flex flex-col lg:flex-row">
      <div className="w-full lg:w-3/5">
        <div className="h-[500px] animate-pulse rounded-lg bg-gray-200" />
      </div>

      <div className="mt-4 lg:mt-0 lg:w-[35%]">
        <div className="animate-pulse rounded-lg bg-gray-200 p-6 shadow">
          <div className="mb-4 h-8 rounded bg-gray-300" />
          <div className="mb-4 h-12 rounded bg-gray-300" />
          <div className="h-12 rounded bg-gray-300" />
        </div>
      </div>
    </div>

    <div className="mt-6">
      <div className="animate-pulse rounded-lg bg-gray-200 p-4">
        <div className="mb-4 h-6 rounded bg-gray-300" />
        <div className="h-12 rounded bg-gray-300" />
      </div>
    </div>

    <div className="mt-6">
      <div className="animate-pulse rounded-lg bg-gray-200 p-4 shadow">
        <div className="mb-4 h-6 rounded bg-gray-300" />
        <div className="space-y-2">
          <div className="flex justify-between">
            <div className="h-4 w-2/4 rounded bg-gray-300" />
            <div className="h-4 w-1/4 rounded bg-gray-300" />
          </div>
          <div className="flex justify-between">
            <div className="h-4 w-2/4 rounded bg-gray-300" />
            <div className="h-4 w-1/4 rounded bg-gray-300" />
          </div>
          <div className="flex justify-between">
            <div className="h-4 w-2/4 rounded bg-gray-300" />
            <div className="h-4 w-1/4 rounded bg-gray-300" />
          </div>
          <div className="flex justify-between">
            <div className="h-4 w-2/4 rounded bg-gray-300" />
            <div className="h-4 w-1/4 rounded bg-gray-300" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

import React from 'react';

import CrossIcon from './../../../assets/icons/crossCircle.svg';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  headerTitle?: string;
  width: string;
  height: string;
  maxWidth?: string;
  maxHeight?: string;
  headerColor?: string;
  children: React.ReactNode;
  IconClassName?: string;
  containerClassName?: string;
  headerClassName?: string;
  overFlow?: string;
}
const ConfirmationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  headerTitle,
  width,
  height,
  maxWidth = '',
  maxHeight = '',
  overFlow = '',
  IconClassName,
  containerClassName = '',
  headerClassName = '',
  headerColor = 'bg-dark-blue',
}) => {
  if (!isOpen) return null;
  return (
    <>
      <div className="fixed inset-0 bg-gray-500 opacity-50" />
      <div
        className={`fixed inset-0 flex items-center ${containerClassName} z-10 justify-center`}
      >
        <div
          style={{ width, height, maxHeight, maxWidth }}
          className={`relative size-full ${overFlow} max-w-screen-lg rounded-2xl bg-white shadow-lg`}
        >
          {headerTitle && (
            <div className={` ${headerColor} m-0  ${headerClassName}  p-4`}>
              <button
                type="button"
                className={`absolute right-6 top-2 size-[36px] rounded-full  ${IconClassName} text-lg text-gray-600 hover:text-gray-900`}
                onClick={onClose}
              >
                <img
                  src={CrossIcon}
                  alt="Cross Icon"
                  width={50}
                  height={50}
                  className="m-2  cursor-pointer items-center justify-center text-3xl  text-white"
                />
              </button>
              <span className="text-lg font-medium  text-white">
                {headerTitle}
              </span>
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  );
};
export default ConfirmationModal;

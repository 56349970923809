import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { getTokenFromLocalStorage } from 'contexts/authContext';
import { getRefreshToken } from 'services/restApis';

import SessionExpireRedirect from './sessionExpire';

/**
 * Custom error class for handling response errors
 */
export class ResponseError extends Error {
  public response: AxiosResponse;

  constructor(response: AxiosResponse) {
    super(response.statusText);
    this.response = response;
  }
}

interface RequestConf extends AxiosRequestConfig {
  withAuthToken?: boolean;
}

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}
const refreshAndRetryQueue: RetryQueueItem[] = [];
let isRefreshing = false;

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  // eslint-disable-next-line consistent-return
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      const originalRequest = error.config as AxiosRequestConfig & {
        retry?: boolean;
      };

      if (error.response?.status === 401 && !originalRequest.retry) {
        if (!isRefreshing) {
          isRefreshing = true;
          originalRequest.retry = true;
          try {
            const response: any = await getRefreshToken();
            localStorage.setItem('userToken', response.access_token);
            localStorage.setItem('refresh_token', response.refresh_token);

            if (error.config)
              // eslint-disable-next-line no-param-reassign
              error.config.headers.Authorization = `Bearer ${response.access_token}`;
            if (originalRequest.headers)
              originalRequest.headers.Authorization = `Bearer ${response.access_token}`;

            refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
              axiosInstance.request(config).then(resolve).catch(reject);
            });

            refreshAndRetryQueue.length = 0;
            // eslint-disable-next-line @typescript-eslint/return-await
            return axiosInstance(originalRequest);
          } catch (refreshError) {
            SessionExpireRedirect(401);
            throw refreshError;
          } finally {
            isRefreshing = false;
          }
        }
        return new Promise<void>((resolve, reject) => {
          refreshAndRetryQueue.push({
            config: originalRequest,
            resolve,
            reject,
          });
        });
      }
    } else {
      throw error;
    }
  },
);

axiosInstance.interceptors.request.use(
  (config: any) => {
    const modifiedConfig = { ...config };

    if (modifiedConfig.withAuthToken) {
      const accessToken = getTokenFromLocalStorage();
      modifiedConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    return modifiedConfig;
  },

  (error: any) => {
    return Promise.reject(error);
  },
);

/**
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to Axios
 * @param  {boolean} [withAuthToken] The authToken we want to pass to “header request”
 *
 * @return {object}           The response data
 */
export const axiosService = async function axiosService<Response>(
  url: string,
  init?: RequestConf,
): Promise<Response> {
  if (typeof window !== 'undefined' && !window.navigator.onLine) {
    throw new Error('You are offline!');
  }

  const response = await axiosInstance(url, init);
  return response.data;
};

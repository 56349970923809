import type { InputProps as AntdInputProps } from 'antd';
import { Input as AntdInput } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface CustomInputProps extends AntdInputProps {
  icon?: React.ReactNode;
  containerClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  height?: string;
  iconClassName?: string;
  name: string;
  valueAsNumber?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  icon,
  placeholder,
  inputClassName = '',
  containerClassName = '',
  height = '50px',
  iconClassName = '',
  name,
  valueAsNumber = false,
  ...props
}) => {
  const padding = clsx(icon ? 'pl-14' : 'pl-4');
  const { control } = useFormContext() ?? {};

  if (!control) return null;

  return (
    <div className={`relative ${containerClassName}`}>
      {icon && <div className={`absolute left-5 ${iconClassName}`}>{icon}</div>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <AntdInput
            className={`w-full rounded-md border-2 ${padding} ${inputClassName}`}
            placeholder={placeholder}
            aria-label={placeholder}
            style={{ height }}
            {...field}
            {...props}
            onChange={e => {
              const value = valueAsNumber
                ? parseFloat(e.target.value)
                : e.target.value;
              field.onChange(value);
            }}
          />
        )}
      />
    </div>
  );
};

export default CustomInput;

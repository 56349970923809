import './style.scss';

import { Button, Tabs } from 'antd';
import SearchInput from 'app/components/SearchInput';
import SearchIcon from 'assets/icons/searchIcon.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegCheckCircle } from 'react-icons/fa';
import { FaRegStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getButtonStyling } from 'utils/commonFunction';

import { History } from './history';
import { Listing } from './listing';
import { PaymentListing } from './paymentList';

export const Influencer = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [activeTabKey, setActiveTabKey] = useState('listing');

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const Content = [
    {
      key: 'listing',
      label: t('tab_label.listing'),
      children: <Listing />,
    },
    {
      key: 'history',
      label: t('tab_label.history'),
      children: <History />,
    },
    {
      key: 'payment',
      label: t('tab_label.payment'),
      children: <PaymentListing />,
    },
  ];

  return (
    <div className="influencer-main rounded-xl border border-gray-300 bg-white p-7 shadow-md">
      <div className="flex flex-row justify-between gap-y-3 pb-5 max-[1130px]:flex-col">
        <div className="flex flex-col">
          <div className="text-lg font-bold">{t('sideBar.influencer')}</div>
          <div className="flex flex-row">
            <Link to="/">{t('addProduct.dashboard')}</Link>
            <span className="px-2">/</span>
            <div className="text-blue-800">
              <Link to="/influencer">{t('sideBar.influencer')}</Link>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-y-4 max-[1130px]:flex-col-reverse  ">
          <div className="flex items-center gap-1 gap-y-3 pr-2 max-[1130px]:justify-end max-[500px]:flex-col">
            {activeTabKey === 'history' ? (
              <form className=" max-w-md max-[500px]:max-w-full ">
                <SearchInput
                  value={searchValue}
                  onChange={handleSearchChange}
                  iconPosition="left"
                  placeholder={t('tab_label.search_by_date')}
                  icon={SearchIcon}
                  inputClassName="py-2 text-sm cursor-pointer w-full gap-2 flex"
                />
              </form>
            ) : null}
            {activeTabKey !== 'payment' ? (
              <form className=" max-w-md max-[500px]:max-w-full">
                <SearchInput
                  value={searchValue}
                  onChange={handleSearchChange}
                  iconPosition="left"
                  placeholder={t('tab_label.search_by_name')}
                  icon={SearchIcon}
                  inputClassName="py-2 text-sm cursor-pointer w-full gap-2 flex"
                />
              </form>
            ) : null}
          </div>
        </div>
      </div>{' '}
      <div className="flex flex-wrap items-center justify-between gap-5 pr-3 max-[900px]:flex-col max-[900px]:items-start">
        <div className="flex flex-wrap items-center gap-6">
          <Button
            className={getButtonStyling(activeTabKey === 'listing')}
            onClick={() => {
              setActiveTabKey('listing');
            }}
          >
            <span>{t('tab_label.listing')}</span>
          </Button>
          <Button
            className={getButtonStyling(activeTabKey === 'history')}
            onClick={() => {
              setActiveTabKey('history');
            }}
          >
            <FaRegCheckCircle
              size={15}
              color={activeTabKey === 'history' ? '#303B97' : 'black'}
            />

            <span>{t('tab_label.history')}</span>
          </Button>
          <Button
            className={getButtonStyling(activeTabKey === 'payment')}
            onClick={() => {
              setActiveTabKey('payment');
            }}
          >
            <FaRegStar
              size={15}
              color={activeTabKey === 'payment' ? '#303B97' : 'black'}
            />
            <span>{t('tab_label.payment')}</span>
          </Button>
        </div>
      </div>
      <Tabs
        items={Content}
        activeKey={activeTabKey}
        type="card"
        className="hide-influencer-tabs-nav mt-6"
      />
    </div>
  );
};

/**
 * Asynchronously loads the component for HomePage
 */

import { LoadingIndicator } from 'app/components/LoadingIndicator';
import * as React from 'react';
import { lazyLoad } from 'utils/loadable';

export const ProductListing = lazyLoad(
  () => import('./index'),
  module => module.ProductListing,
  {
    fallback: (
      <div className="flex h-screen w-full justify-center">
        <LoadingIndicator />
      </div>
    ),
  },
);
